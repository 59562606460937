
.article {
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
  border : 0px solid black;
}

.article h1{
  color: #003366;
  text-align: left;
  font-size: 20px!important;
  font-weight: bold;
  margin-top: 3rem;

  font-size: 3rem !important;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.article h2{
  color: #003366;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 2rem;
}

.article h3{
  color: ##003366;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  text-decoration-thickness : 0.15rem;
  text-underline-offset: 0.3rem;
  font-size: 18px!important;
  font-style: normal;
  text-align: left;
}

.article h4{
  color: ##003366!important;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  text-decoration: none;
  text-decoration-thickness : 0.15rem;
  text-underline-offset: 0.3rem;
  font-size: 16px!important;
  font-style: normal;
  text-align: left;
}

.article span{
  display: block;
  height: 15px;
}

.bullet-list{
  margin-top: 1rem;
}

.short-break {
  border : 2px solid black;
  background-color: transparent;
  height: 30px; /* Adjust the line-height to make the break shorter */
}

.article img{
  filter: drop-shadow(5px 5px 5px #222);
  display : block;
  width: 66.66%;
  margin : 0 auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.pop-out-image {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }

  .pop-out-image:active {
    transform: scale(1.4);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }

.flex-card {
  display: flex;
  gap: 35px;
  margin-block: 40px;
  align-items: center;
}

.flex-card img{
  filter: drop-shadow(5px 5px 5px #222);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article .flex-card img {
  width: 45%;
  border-radius: 10px;
  margin: 0 !important;
}
.flex-card.flex-reverse {
  flex-direction: row-reverse;
}

.footer img {
  width: 100% !important;
  margin: 0 !important;
  margin-right: 0 !important;
  margin: 0 !important;
  margin-top: 2rem!important;
}

.blockquote {
  /* background-color: #f9f9f9;
  margin: 20px 0;
  width: max-content;
  margin-inline: auto;
  padding: 5px 5px;
  border-left: 10px solid #ccc; */
  margin-top: 0rem!important;
  color: white !important; /* Teal text color */
  margin: 0;
  text-decoration: none !important;

  margin-bottom: 0 !important;
}
.main-block {
  background-color: rgb(0, 51, 102);
  border-left: 5px solid rgb(0, 51, 102);
  margin: 30px 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  font-style: italic;
  width: max-content;
  margin-inline: auto !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-top: 1px solid #ddd;
  font-family: Arial, sans-serif;
  margin-bottom: 1rem;
}

.pagination a {
  text-decoration: none;
  color: #333;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.pagination a:hover {
  background-color: #007bff;
  color: #fff;
}

.pagination .prev,
.pagination .next {
  font-weight: bold;
}

.pagination .page.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  pointer-events: none;
}

.pagination .dots {
  padding: 8px 12px;
  color: #999;
}


@media (max-width: 767px) {
  .article h2 {
    font-size: 2rem !important;
  }
  .main-content {
    width: 100% !important;
    max-width: none !important;
  }
  .article .flex-card img {
    width: 100%;
    margin: 0 !important;
  }
  .flex-card {
    margin-block: 20px !important;
  }
  .flex-card,
  .flex-card.flex-reverse {
    flex-direction: column;
  }
  .flex-card p {
    margin-top: 20px;
  }
}
@media (max-width: 550px) {
  .article {
    padding-inline: 20px !important;
  }
  .article h3 {
    font-size: 17px !important;
  }
}
@media (max-width: 460px) {
  .main-block {
    padding-inline: 10px;
  }
}
@media (max-width: 430px) {
  .article h3 {
    font-size: 15px !important;
  }
}
